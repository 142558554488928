<template>
  <div>
    <v-subheader v-if="title">{{ title }}</v-subheader>
    <v-list
      dense
    >
      <v-list-item
        class="d-flex justify-space-between"
        v-for="(item, key) in items"
        :key="`customers-item-${key}`"
      >
        <v-list-item-content class="flex-grow-1 flex-shrink-0">
          <v-list-item-title>{{ key }}</v-list-item-title>
        </v-list-item-content>
        <v-list-item-content>
          <v-list-item-title>{{ item || '-' }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>
<script>
export default {
  props: {
    items: {
      default: () => [],
      type: [Array, Object]
    },
    title: {
      type: String
    }
  },
  name: 'List'
}
</script>