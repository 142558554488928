<template>
  <loader-hoc
    v-model="inProcess"
  >
    <template v-if="order">
      <div class="display-1">
        Order reference <router-link :to="`/dashboard/user/${userId}/customer/${customerId}/subscription/${subscriptionId}/order/${orderId}`">#{{ order.RefNo }}</router-link>
      </div>
      <v-container fluid>
        <v-row>
          <v-col cols="12">
            <card>
              <div class="d-flex align-center">
                <v-card-title>Order status: {{ order.Status }}</v-card-title>
                <v-spacer />
                <div class="px-4 font-weight-medium grey--text" v-if="order.TestOrder">{{ `Its's test order` }}</div>
              </div>
            </card>
          </v-col>
          <v-col cols="12">
            <card>
              <v-card-title>Order details</v-card-title>
              <v-divider />
              <v-data-table
                hide-default-header
                hide-default-footer
                :items="productItems"
                :headers="productHeaders"
              >
                <template v-slot:item.info="{ item }">
                  <v-list-item
                    two-line
                  >
                    <v-list-item-content>
                      <v-list-item-title class="font-weight-medium">
                        {{ item.info.Quantity }} x {{ item.info.ProductDetails.Name }}
                      </v-list-item-title>
                      <v-list-item-subtitle
                        class="mt-1"
                      >
                        <router-link :to="`/dashboard/user/${userId}/customer/${customerId}/subscription/${subscriptionId}/`">
                        View subscription</router-link>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <template v-slot:item.price="{ item }">
                  {{ item.info.Price.NetPrice }}&nbsp;{{ item.info.Price.Currency }}
                </template>
              </v-data-table>
              <v-divider />
              <v-card-actions
                class="pa-4"
              >
                <v-btn
                  dark
                  color="primary"
                >
                  Request refund...
                </v-btn>
                <v-spacer />
                <v-btn class="mr-2">Send Invoice</v-btn>
                <v-btn>Print</v-btn>
              </v-card-actions>
            </card>
          </v-col>
          <!-- <v-col cols="12">
            <card
              title="Order comments"
            >
              {{ orderComments }}
            </card>
          </v-col> -->
          <v-col cols="6">
            <card>
              <list
                title="Billing Details"
                :items="billingDetails"
              />
            </card>
          </v-col>
          <v-col cols="6">
            <card>
              <list 
                title="Delivery Details"
                :items="deliveryDetails"
              />
            </card>
          </v-col>
          <v-col cols="12">
            <card
              title="Order History"
            >
              <v-data-table
                dense
                hide-default-footer
                :items="historyItems"
                :headers="historyHeaders"
              ></v-data-table>
            </card>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </loader-hoc>
</template>
<script>

import LoaderHoc from '@/components/loader-hoc'
import Card from '@/components/card'
import List from '@/components/list'

export default {
  name: 'DashboardSubscriptionOrder',
  components: {
    LoaderHoc,
    List,
    Card
  },
  data: () => ({
    inProcess: true,
    order: null,
    userData: null
  }),
  created() {
    this.init()
  },
  computed: {
    orderComments() {
      console.log(this.order)
      return []
    },
    productItems() {
      const result = this.order.Items.map(item => {
        console.log(item)
        return {
          info: item,
          price: '3$' 
        }
      })

      return result
    },
    productHeaders() {
      const result = [
        {
          text: 'Info',
          value: 'info'
        },
        {
          text: 'Price',
          value: 'price'
        }
      ]
      return result
    },
    history() {
      const result = Object.values(this.userData[1].externalData.verifone.orders || {}).filter(order => order.RefNo === this.orderId)
      return result
    },
    historyItems() {
      const result = this.history.map(historyItem => {
        const { FinishDate, RefNo: order } = historyItem
        return {
          date: FinishDate,
          change: this.changeByType(historyItem),
          data: historyItem,
          order
        }
      })
      
      return result
    },
    historyHeaders() {
      return [
        {
          text: 'Date',
          value: 'date'
        },
        {
          text: 'Change',
          value: 'change'
        }
      ]
    },
    billingDetails() {
      return this.order.BillingDetails
    },
    deliveryDetails() {
      return this.order.DeliveryDetails
    },
    userId() {
      return this.$route.params.userId
    },
    subscriptionId() {
      return this.$route.params.subscriptionId
    },
    customerId() {
      return this.$route.params.customerId
    },
    orderId() {
      return this.$route.params.orderId
    }
  },
  methods: {
    changeByType(item) {
      const { RefNo } = item
      let result = `The order ${RefNo} ...`
      return result
    },
    async init() {
      this.inProcess = true
      this.userData = await this.$store.dispatch('getCustomer', this.userId)

      this.order = this.userData[1].externalData.verifone.orders[this.orderId]

      this.inProcess = false
    }
  }
}
</script>